'use client';
import Brand from '@/components/Brand';
import {
  ArrowRight,
  Blocks,
  Camera,
  Leaf,
  LifeBuoy,
  LucideIcon,
  Menu,
  Newspaper,
  PackagePlus,
  Workflow,
} from 'lucide-react';
import Session from './Session';
import Link from '@/components/ui/Link';
import ClientOnly from '@/components/ClientOnly';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/Sheet';
import cn from 'mxcn';
import { ReactNode, useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/Accordion';
import { Badge } from '@/components/ui/Badge';

export function MobileNavigationMenu() {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger className="p-2">
        <Menu size={24} />
      </SheetTrigger>
      <SheetContent side="right">
        <Brand className="-mt-2" />
        <div className="h-6" />
        <Accordion type="single" collapsible className="stack grow">
          <MobileNavGroup title="Product">
            <MobileNavLink
              onClick={close}
              icon={Camera}
              href="/product/screenshots"
              description="Capture rich annotated product screenshots."
            >
              Screenshots
            </MobileNavLink>
            <MobileNavLink
              onClick={close}
              icon={Leaf}
              href="/product/updates"
              description="Screenshots that are never out-of-date."
            >
              Updates
            </MobileNavLink>
            <MobileNavLink
              onClick={close}
              icon={Workflow}
              href="/product/workflows"
              description="Granular publishing workflows ensure consistent quality."
            >
              Workflows
            </MobileNavLink>
            <MobileNavLink
              onClick={close}
              icon={Blocks}
              href="/product/integrations"
              description="Granular publishing workflows ensure consistent quality."
            >
              Integrations
            </MobileNavLink>
          </MobileNavGroup>

          <MobileNavGroup title="Resources">
            <MobileNavLink
              onClick={close}
              icon={LifeBuoy}
              href="/support"
              description="Get help"
            >
              Support
            </MobileNavLink>
            <MobileNavLink
              onClick={close}
              icon={PackagePlus}
              href="/changelog"
              description="See the latest features"
            >
              Changelog
            </MobileNavLink>
            <MobileNavLink
              onClick={close}
              icon={Newspaper}
              href="/blog"
              description="Advice"
            >
              Blog
            </MobileNavLink>
          </MobileNavGroup>

          <div className="row-2 items-center py-4">
            <Badge variant="success" className="shadow-none opacity-70">
              Free
            </Badge>
            <Link
              href="/analyze"
              className="row items-center font-medium text-lg text-contrast-600"
              onClick={close}
            >
              Analyze your site <ArrowRight size={14} className="opacity-50" />
            </Link>
          </div>

          {/* <Link
          className="font-medium text-contrast-600 text-lg pl-9 py-3 flex hover:bg-contrast-0 rounded bg-opacity-50"
          href="/pricing"
        >
          Pricing
        </Link> */}

          <div className="h-16" />

          <div className="stack">
            <ClientOnly>
              <Session />
            </ClientOnly>
          </div>
        </Accordion>
      </SheetContent>
    </Sheet>
  );
}
function MobileNavGroup(props: { title: string; children: ReactNode }) {
  return (
    <AccordionItem value={props.title}>
      <AccordionTrigger className="font-medium text-contrast-600 text-lg">
        {props.title}
      </AccordionTrigger>
      <AccordionContent>{props.children}</AccordionContent>
    </AccordionItem>
  );
}
function MobileNavLink(props: {
  className?: string;
  icon: LucideIcon;
  href: string;
  description: string;
  onClick?: () => void;
  children: ReactNode;
}) {
  return (
    <Link
      href={props.href}
      className={cn(
        props.className,
        'row-2 items-start group py-3 pl-8 focus:bg-contrast-50 rounded'
      )}
      onClick={props.onClick}
    >
      <props.icon size={22} className="flex-none text-brand-600 transition" />
      <span className="stack-1 px-1.5 shrink">
        <span className="underline-offset-4 decoration-2 decoration-gray-300 text-lg">
          {props.children}
        </span>
        <span className="leading-tight text-gray-500">{props.description}</span>
      </span>
    </Link>
  );
}
