import cn from 'mxcn';

export default function Logo(props: { className?: string }) {
  return (
    <svg
      viewBox="0 0 122 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(props.className, '')}
    >
      <path
        d="M67.8366 40.2017C64.9468 34.0109 56.1435 34.0109 53.2537 40.2017L29.5515 90.9783C27.9525 94.4038 30.7324 98.2549 34.487 97.8158L49.5934 96.0495V107.788C49.5934 110.454 51.755 112.616 54.4215 112.616H66.6687C69.3352 112.616 71.4968 110.454 71.4968 107.788V96.0495L86.6032 97.8158C90.3579 98.2549 93.1378 94.4037 91.5388 90.9783L67.8366 40.2017Z"
        className="fill-current"
      />
      <path
        d="M60.5821 27.1765C58.8045 27.1765 57.3634 25.7354 57.3634 23.9578L57.3634 12.6923C57.3634 10.9147 58.8045 9.47365 60.5821 9.47365C62.3598 9.47365 63.8008 10.9147 63.8008 12.6923L63.8008 23.9578C63.8008 25.7354 62.3598 27.1765 60.5821 27.1765Z"
        className="fill-current"
      />
      <path
        d="M74.9576 34.3021C73.5959 33.1595 73.4183 31.1293 74.5609 29.7675L81.8022 21.1377C82.9448 19.776 84.975 19.5983 86.3368 20.741C87.6985 21.8836 87.8761 23.9138 86.7335 25.2756L79.4922 33.9054C78.3496 35.2671 76.3194 35.4448 74.9576 34.3021Z"
        className="fill-current"
      />
      <path
        d="M35.575 20.2795C36.9367 19.1369 38.9669 19.3145 40.1096 20.6762L47.3508 29.306C48.4935 30.6678 48.3159 32.698 46.9541 33.8406C45.5924 34.9833 43.5622 34.8057 42.4195 33.4439L35.1782 24.8141C34.0356 23.4524 34.2132 21.4221 35.575 20.2795Z"
        className="fill-current"
      />
    </svg>
  );
}
