import { useMemo, useRef, useState } from 'react';

export default function useHover(delay?: number) {
  const [isHovering, setIsHovering] = useState(false);
  const delayRef = useRef<NodeJS.Timeout | null>(null);
  const bindings = useMemo(
    () => ({
      onMouseEnter: () => {
        setIsHovering(true);
        if (delayRef.current) clearTimeout(delayRef.current);
      },
      onMouseLeave: () => {
        if (typeof delay === 'number') {
          delayRef.current = setTimeout(() => {
            setIsHovering(false);
          }, delay);
        } else {
          setIsHovering(false);
        }
      },
    }),
    [delay]
  );
  return [isHovering, bindings] as const;
}
