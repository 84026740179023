'use client';
import { type ReactNode } from 'react';
import cn from 'mxcn';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import useHover from '@/lib/hooks/useHover';
import { ChevronDown, LucideIcon } from 'lucide-react';
import Link from '@/components/ui/Link';

function Root(props: { className?: string; children: ReactNode }) {
  return (
    <>
      <div className={cn(props.className, 'row items-center')}>
        {props.children}
      </div>
    </>
  );
}

function Item(props: {
  className?: string;
  label: string;
  width: number;
  children: ReactNode;
}) {
  const [isHovering, bind] = useHover();
  return (
    <>
      <div className={cn('relative', props.className)} {...bind}>
        <div
          className={cn(
            'row-1 cursor-default items-center px-4 py-5 font-medium underline-offset-4 decoration-2 decoration-gray-300',
            isHovering && 'underline'
          )}
        >
          {props.label}{' '}
          <ChevronDown
            size={12}
            className={cn(
              'transition',
              isHovering ? 'opacity-100' : 'opacity-50'
            )}
          />
        </div>
        <LayoutGroup>
          <AnimatePresence>
            {isHovering && (
              <motion.div
                layout
                layoutId="nav-menu-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0 }}
                className={cn(
                  props.className,
                  isHovering ? 'absolute' : 'hidden',
                  'top-full left-0 shadow-lg border border-contrast-50 overflow-hidden bg-contrast-0 rounded'
                )}
                style={{ width: props.width }}
              >
                <motion.div layout className="row">
                  {props.children}
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </LayoutGroup>
      </div>
    </>
  );
}

function ItemLinks(props: { children: ReactNode }) {
  return <div className="grow shrink stack py-3 px-6">{props.children}</div>;
}

function TopLevelLink(props: {
  className?: string;
  href: string;
  children: ReactNode;
}) {
  return (
    <>
      <Link
        href={props.href}
        className={cn(
          props.className,
          'row-1 cursor-pointer items-center px-4 py-5 font-medium hover:underline underline-offset-4 decoration-2 decoration-gray-300'
        )}
      >
        {props.children}
      </Link>
    </>
  );
}

function ItemLink(props: {
  className?: string;
  icon: LucideIcon;
  href: string;
  description: string;
  children: ReactNode;
  openInNewTab?: boolean;
}) {
  return (
    <>
      <Link
        href={props.href}
        className={cn(props.className, 'row-2 items-start group py-3')}
        target={props.openInNewTab ? '_blank' : undefined}
      >
        <props.icon
          size={22}
          className="flex-none text-brand-600 opacity-60 group-hover:opacity-100 transition"
        />
        <span className="stack-1 px-1.5 shrink">
          <span className="group-hover:underline underline-offset-4 decoration-2 decoration-gray-300">
            {props.children}
          </span>
          <span className="text-sm leading-tight text-gray-500">
            {props.description}
          </span>
        </span>
      </Link>
    </>
  );
}

function Callout(props: { className?: string; children: ReactNode }) {
  return (
    <>
      <div className={cn(props.className, 'p-6 flex-none bg-contrast-100')}>
        {props.children}
      </div>
    </>
  );
}

const NavigationMenu = Object.assign(Root, {
  Item,
  ItemLinks,
  TopLevelLink,
  ItemLink,
  Callout,
});

export default NavigationMenu;
