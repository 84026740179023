import { Button } from '@/components/ui/Button';
import Link from '@/components/ui/Link';
import { ArrowRight } from 'lucide-react';
import config from '@/config';

export default function Session() {
  if (hasSessionHint()) {
    return (
      <>
        <Link href="/projects" className="flex">
          <Button variant="primary" size="sm" icon={ArrowRight}>
            Dashboard
          </Button>
        </Link>
      </>
    );
  }
  return (
    <div className="stack-4 items-stretch md:row-4 md:items-center">
      <a href={config.marketing.bookDemoLink} className="flex" target="_blank">
        <Button
          variant="primary"
          size="default"
          icon={ArrowRight}
          className="grow"
        >
          Request demo
        </Button>
      </a>
      <Link href="/login" className="flex">
        <Button
          variant="secondary"
          icon={ArrowRight}
          size="default"
          className="grow"
        >
          Login
        </Button>
      </Link>
    </div>
  );
}

function hasSessionHint() {
  return document.cookie.includes('cedar-session-hint');
}
