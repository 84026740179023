import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import cn from 'mxcn';

const badgeVariants = cva(
  'inline-flex items-center justify-center rounded-md border shadow-sm border-contrast-200 px-1.5 py-0.5 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-contrast-950 focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-contrast-900 text-contrast-50 shadow',
        success: 'border-transparent bg-brand-700 text-white shadow',
        secondary: 'border-transparent bg-contrast-100 text-contrast-900',
        warning: 'border-transparent bg-yellow-600 text-gray-900',
        destructive: 'border-transparent bg-red-600 text-white',
        outline: 'text-contrast-500 border border-contrast-200',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
