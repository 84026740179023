import { type ReactNode } from 'react';
import cn from 'mxcn';

export default function Container(props: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <>
      <div
        className={cn(
          props.className,
          'mx-auto container max-w-screen-lg stack px-4 md:px-8'
        )}
      >
        {props.children}
      </div>
    </>
  );
}
