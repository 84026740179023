import Logo from '@/components/Logo';
import cn from 'mxcn';

export default function Brand(props: {
  className?: string;
  wordmark?: boolean;
}) {
  return (
    <>
      <div
        className={cn(
          '-ml-1 pr-6 font-bold font-brand text-xl row-1 items-center',
          props.className
        )}
      >
        <Logo className="w-[1.5em] h-[1.5em] -mt-[0.25em]" />
        {props.wordmark !== false && 'Cedar'}
      </div>
    </>
  );
}
