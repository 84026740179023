'use client';
import Container from '@/components/ui/Container';
import Brand from '@/components/Brand';
import NavigationMenu from './NavigationMenu';
import {
  ArrowRight,
  Blocks,
  Camera,
  Leaf,
  LifeBuoy,
  Newspaper,
  PackagePlus,
  SearchCheck,
  Workflow,
} from 'lucide-react';
import Session from './Session';
import Link from '@/components/ui/Link';
import ClientOnly from '@/components/ClientOnly';
import { MobileNavigationMenu } from './MobileNavigationMenu';
import { Badge } from '@/components/ui/Badge';

export default function Navbar() {
  return (
    <div className="border-b border-contrast-50 bg-contrast-0 bg-opacity-90 backdrop-blur-lg">
      <Container>
        <div className="row py-2 bg-opacity-100">
          <Link href="/" className="items-center flex py-2">
            <Brand />
          </Link>
          <div className="hidden md:row grow">
            <NavigationMenu>
              <NavigationMenu.Item label="Product" width={300}>
                <NavigationMenu.ItemLinks>
                  <NavigationMenu.ItemLink
                    icon={Camera}
                    href="/product/screenshots"
                    description="Capture rich annotated product screenshots."
                  >
                    Screenshots
                  </NavigationMenu.ItemLink>
                  <NavigationMenu.ItemLink
                    icon={Leaf}
                    href="/product/updates"
                    description="Screenshots that are never out-of-date."
                  >
                    Updates
                  </NavigationMenu.ItemLink>
                  <NavigationMenu.ItemLink
                    icon={Workflow}
                    href="/product/workflows"
                    description="Granular publishing workflows ensure consistent quality."
                  >
                    Workflows
                  </NavigationMenu.ItemLink>
                  <NavigationMenu.ItemLink
                    icon={Blocks}
                    href="/product/integrations"
                    description="Granular publishing workflows ensure consistent quality."
                  >
                    Integrations
                  </NavigationMenu.ItemLink>
                </NavigationMenu.ItemLinks>

                {/* <NavigationMenu.Callout className="w-64">
                  <div className="aspect-[4/3] bg-contrast-200 rounded"></div>
                  <div className="font-medium text-sm"></div>
                </NavigationMenu.Callout> */}
              </NavigationMenu.Item>

              <NavigationMenu.Item label="Resources" width={200}>
                <NavigationMenu.ItemLinks>
                  <NavigationMenu.ItemLink
                    icon={LifeBuoy}
                    href="mailto:help@trycedar.com"
                    description="Get help with Cedar"
                    openInNewTab
                  >
                    Support
                  </NavigationMenu.ItemLink>
                  <NavigationMenu.ItemLink
                    icon={PackagePlus}
                    href="/changelog"
                    description="See the latest features"
                  >
                    Changelog
                  </NavigationMenu.ItemLink>
                  <NavigationMenu.ItemLink
                    icon={Newspaper}
                    href="/blog"
                    description="Tips and advice from the team"
                  >
                    Blog
                  </NavigationMenu.ItemLink>
                </NavigationMenu.ItemLinks>

                {/* <NavigationMenu.Callout className="w-64">
                  <div className="aspect-[4/3] bg-contrast-200 rounded"></div>
                  <div className="font-medium text-sm">Latest changelog</div>
                </NavigationMenu.Callout> */}
              </NavigationMenu.Item>

              <div className="row items-center ml-2">
                <Badge variant="success" className="shadow-none opacity-70">
                  Free
                </Badge>
                <NavigationMenu.TopLevelLink href="/analyze">
                  Analyze your site{' '}
                  <ArrowRight size={14} className="opacity-50" />
                </NavigationMenu.TopLevelLink>
              </div>
            </NavigationMenu>

            <div className="ml-auto row items-center">
              <ClientOnly>
                <Session />
              </ClientOnly>
            </div>
          </div>
          <div className="row md:hidden ml-auto">
            <MobileNavigationMenu />
          </div>
        </div>
      </Container>
    </div>
  );
}
